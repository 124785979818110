const links = document.querySelectorAll<HTMLAnchorElement>('header nav a, footer nav a');
const menuTrigger = document.querySelector<HTMLInputElement>('#menu-trigger');
const content = new Map<number, string>();
const offsets: number[] = [];
const defaultTitle = document.title;
let currentTitle: string;
let timerId;

export default () => {
  document.body.parentElement.style.scrollBehavior = 'smooth';
  links.forEach(link => {
    const href = link.getAttribute('href');
    content.set(document.getElementById(href.substring(1))?.offsetTop || 0, href);

    link.addEventListener('click', () => {
      disableUpdate();
      menuTrigger.checked = false;
      link.parentElement.querySelector('.active')?.classList.remove('active');
      link.classList.add('active');
      updateTitle();
    });
  });
  offsets.push(...Array.from(content.keys()));

  doUpdate();
  enableUpdate();
  window.addEventListener('scroll', () => {
    clearTimeout(timerId);
    timerId = setTimeout(enableUpdate, 100);
  }, { passive: true });
};

function enableUpdate() {
  window.addEventListener('scroll', doUpdate, { passive: true });
}

function disableUpdate() {
  window.removeEventListener('scroll', doUpdate);
}

function doUpdate() {
  const position = Math.round(window.scrollY + window.innerHeight / 2);
  const copy = [...offsets, position].sort((a, b) => a - b);
  const index = copy.indexOf(position) - 1;
  const id = content.get(offsets[index]) || links.item(0).getAttribute('href');
  const [hash] = window.location.hash.split('?');

  if (id !== hash) {
    window.history.replaceState(null, '', id);
  }

  links.forEach(link => {
    const href = link.getAttribute('href');
    link.classList.toggle('active', href === hash);
  });

  updateTitle();
}

function updateTitle() {
  const [hash] = window.location.hash.split('?');
  currentTitle = hash === '#home' ? '' : document.querySelector(hash).getAttribute('aria-label');
  document.title = (currentTitle ? currentTitle + ' | ' : '') + defaultTitle;
}
