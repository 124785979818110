import analytics from "./analytics";

export default () => {
  document.querySelectorAll('.slider').forEach(slider => {
    let section: Element = slider;
    while(!section.id) {
      section = section.parentElement;
    }

    slider.addEventListener('click', event => update(section.id, slider, event.target, false));
    slider.addEventListener('keypress', event => {
      if (event instanceof KeyboardEvent && event.key === 'Enter') {
        update(section.id, slider, event.target, true);
      }
    });
  });
};

function update(section: string, slider: Element, target: EventTarget, autoFocus: boolean) {
  analytics('click', section, 'slider');

  let slide = (target as HTMLElement);
  while(!slide.parentElement.classList.contains('slider')) {
    slide = slide.parentElement;
  }

  const content = Array.from(slider.querySelectorAll<HTMLElement>('li'));
  const index = content.findIndex(el => el === slide);
  slider.removeChild(content[index]);

  if (index === 0) {
    slider.appendChild(content[index]);
  } else {
    slider.insertBefore(content[index], content[0]);
  }

  if (autoFocus) {
    slider.querySelector<HTMLElement>('li:first-child').focus();
  }
}
