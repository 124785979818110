export default () => {
  const siteToken = '6LdGFKQiAAAAAJXPW43PiLbTdGKM5QIBZxZYwVKo';

  document.querySelectorAll('form').forEach(form => {
    const dialog = form.querySelector<HTMLDialogElement>('dialog');
    const message = dialog.querySelector<HTMLParagraphElement>('p');
    const close = dialog.querySelector<HTMLButtonElement>('button[type=button]');
    const send = form.querySelector<HTMLButtonElement>('button[type=submit]');

    close.addEventListener('click', () => {
      dialog.close();
      message.innerText = '';
    });

    form.addEventListener('submit', event => {
      event.preventDefault();
      send.disabled = true;

      grecaptcha.ready(async () => {
        const token = await grecaptcha.execute(siteToken, {action: 'submit'});
        const body = new FormData(form);
        body.set('g-recaptcha-response', token);
        const res = await fetch(form.action, {
          body,
          method: form.method
        });

        message.innerText = await res.text();
        dialog.showModal();
        send.disabled = false;
        if (res.ok) {
          form.reset();
        }
      });
    });
  });
}
