export default () => {
  if (localStorage.getItem('cookies') === 'false') {
    return;
  }

  const dialog = document.querySelector<HTMLDialogElement>('#cookies');
  dialog.querySelector('.close').addEventListener('click', () => {
    dialog.close();
    localStorage.setItem('cookies', 'false');
  }, {once: true});
  dialog.show();
}
