const header = document.querySelector('header');

export default () => {
  window.addEventListener('scroll', () => toggle(), { passive: true });
  toggle();
};

function toggle() {
  header.classList.toggle('attached', window.scrollY > 0);
}
