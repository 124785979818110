import analytics from "./analytics";

export default () => {
  const home = document.querySelector<HTMLElement>('#home');
  const svg = home.querySelector<SVGElement>('#home .image svg');
  const config = {
    'moon': 5,
    'back-hill': 7,
    'gate': 10,
    'grass > *:nth-child(1)': 5,
    'grass > *:nth-child(2)': 6,
    'grass > *:nth-child(3)': 7,
    'grass > *:nth-child(4)': 6,
    'grass > *:nth-child(5)': 7,
    'samurai': 30,
    'overlay > *:nth-child(1)': 4,
    'overlay > *:nth-child(2)': 8,
  };

  home.addEventListener('mousemove', event => {
    const hW = window.innerWidth / 2;
    const hH = window.innerHeight / 2;
    const posX = (event.x * 100 / hW) - 100;
    const posY = (event.y * 100 / hH) - 100;

    for (const selector in config) {
      const el = svg.querySelector<SVGGElement>('#' + selector);
      const x = -1 * posX * (config[selector] / 100) / 100;
      const y = -1 * posY * (config[selector] / 100) / 100;
      el.style.transform = `translate(${x}rem, ${y}rem)`;
    }
  }, { passive: true });

  home.querySelector<HTMLAnchorElement>('a').addEventListener('click', () => analytics('click', 'home', 'call_to_action'), { passive: true });
};
