import navigation from './scripts/navigation';
import animation from './scripts/animation';
import header from './scripts/header';
import cookies from './scripts/cookies';
import slider from './scripts/slider';
import form from './scripts/form';
import home from './scripts/home';

window.addEventListener('load', () => navigation(), { passive: true });
animation();
header();
cookies();
slider();
form();
home();
