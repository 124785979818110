const coverage = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 20 : 50;

export default () => {
  window.addEventListener('scroll', animate, { passive: true });
  if (!window.scrollY) {
    animate();
  }
};

function animate() {
  document.querySelectorAll<HTMLElement>('[data-animation]').forEach(el => {
    if (!isVisible(el)) {
      el.classList.add('waiting');
      return;
    }

    const [fn, delay, speed] = JSON.parse(el.dataset.animation);

    if (!fn) {
      return;
    }

    el.classList.add('animated', fn);

    if (delay) {
      el.classList.add(`delay-${delay}`);
    }

    if (speed) {
      el.classList.add(speed);
    }
  });
}

function isVisible(el) {
  if (el.dataset.hasOwnProperty('forceAnimation')) {
    return true;
  }

  const wTop = window.scrollY;
  const wBottom = wTop + window.innerHeight;
  const sTop = el.offsetTop;
  const sHeight = el.clientHeight;
  const sBottom = sTop + sHeight;

  let visibility = 0;
  if (wBottom > sTop && sBottom > wBottom) {
    visibility = (wBottom - sTop) * 100 / sHeight;
  } else if (sBottom > wTop && sTop < wTop) {
    visibility = (sBottom - wTop) * 100 / sHeight;
  } else if (sTop >= wTop && sBottom <= wBottom) {
    visibility = 100;
  }

  return visibility >= coverage;
}
